import { LoginFunction } from './features/loginFunction';
import { Download } from './features/download';
import { BrowserRouter as 
  Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

 
function App() {
  
  return (
    <Router>
        <div className="App">
        <Switch>
        <Route exact path="/download" component={Download} />
          <Route  path="*" component={LoginFunction}/>
    
        
          
        </Switch>
        </div>
       
    </Router>

  );
}

export default App;